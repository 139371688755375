'use strict';

wo$(function () {

    var defaultCriteria = {
        phoneSizeSmall: true,
        phoneSizeMedium: true,
        phoneSizeLarge: true,

        paymentTypeUpfront: true,
        paymentTypePlan: true,

        phoneCostCheap: true,
        phoneCostMidRange: true,
        phoneCostHighEnd: true,

        brands: [],

        filter: '',

        showEditorsPickOnly: false,

        sortBy: 'popular'
    };

    var controller = {
        show: function (wizardMode, selectedHandler, byoPhoneModelId) {
           
            controller.init(wizardMode, selectedHandler, null, byoPhoneModelId);

            controller.phoneModal.modal('show');
        },

        initModel: function (showBrandStep, showPaymentOptions) {
            controller.wizardMode = false;
            controller.phoneSelectedHandler = null;
            controller.pageHeight = null;
            controller.currentStep = null;
            controller.phones = [];
            controller.maxPhones = 4;
            controller.maxPhonesAdvanced = 16;
            controller.filteredPhones = [];
            controller.filteredPhonesAllBrands = [];
            controller.criteria = wo$.extend(true, {}, defaultCriteria);
            controller.criteria.maxUpfront = controller.modalData.defaultMaxUpfront;
            controller.hasValidationErrors = false;
            controller.applicablePaymentTypes = [];
            controller.stepsConfig = [];
            controller.normalSteps = [5, 6];
            controller.embeddedModeSteps = [5];
            controller.steps = null;
            controller.currentStepIndex = null;

            controller.wizardSteps = [1, 2];
            if (showBrandStep)
                controller.wizardSteps.push(3);
            if (showPaymentOptions)
                controller.wizardSteps.push(0);
            controller.wizardSteps.push(4);
            controller.wizardSteps.push(6);
        },

        init: function (wizardMode, selectedHandler, parentElement, byoPhoneModelId) {

            if (parentElement) {
                controller.phoneModal = parentElement;
                controller.isEmbedded = true;
            } else {
                controller.phoneModal = wo$('#modal-phone-picker');
                controller.isEmbedded = false;
            }

            if (!controller.phoneModal.length) return;

            if (byoPhoneModelId) {
                controller.selectedByoPhoneModelId = byoPhoneModelId;
            }

            controller.modalData = WhistleOut.readLookupData(controller.phoneModal, true);

            WhistleOut.applySelectPicker(controller.phoneModal.find('.selectpicker'), { countSelectedText: controller.dropdownDisplay });

            var showBrandStep = controller.modalData.brands.length > 1;
            controller.initModel(showBrandStep, controller.modalData.showPaymentOptions);

            if (controller.isEmbedded) {
                controller.phoneModal.find('div.bootstrap-select button').addClass('btn-sm');
            }

            controller.stepsConfig = controller.modalData.stepsConfig;
            controller.applicablePaymentTypes = controller.modalData.applicablePaymentTypes;
            controller.criteria.brands = controller.modalData.brands;
            controller.phones = controller.modalData.phones;
            controller.filteredPhones = controller.phones.slice().sort(function (a, b) {
                if (a.id === -1) return -1;
                if (a.rank < b.rank) return -1;
                if (a.rank > b.rank) return 1;
                return 0;
            });

            controller.wizardMode = wizardMode;
            controller.phoneSelectedHandler = selectedHandler;

            if (parentElement) {
                controller.steps = controller.embeddedModeSteps;
            } else {
                controller.steps = controller.wizardMode ? controller.wizardSteps : controller.normalSteps;
            }

            controller.phoneModal.find('[data-continue-button]').off().click(function () {
                controller.setCurrentStep(controller.currentStepIndex + 1);
            });

            controller.phoneModal.find('[data-back-button]').off().click(function () {
                controller.setCurrentStep(controller.currentStepIndex - 1);
            });

            controller.phoneModal.on('hidden.bs.modal', function () {
                controller.cleanup();
            });

            controller.phoneModal.on('hide.bs.modal', function () {
                wo$('[data-show-byo-button]').popover('hide');
            });

            controller.phoneModal.on('shown.bs.modal', function () {
                controller.pageHeight = wo$(window).height();
            });

            controller.setCurrentStep(0);
            controller.filterPhones();

            WhistleOut.applySelectPickersStyle(controller.phoneModal);
        },

        cleanup: function () {
            controller.maxPhones = 4;
            controller.maxPhonesAdvanced = 16;
            controller.currentStepIndex = null;
            controller.criteria = wo$.extend(true, {}, defaultCriteria);
            controller.phoneModal.find('input[type="checkbox"]').prop('checked', true);
            controller.phoneModal.off();
        },

        dropdownDisplay: function (selectedCount, totalCount) {
            if (selectedCount === totalCount) {
                return controller.modalData.allText;
            }
            return controller.modalData.selectedText.replace('{0}', selectedCount);


        },

        setCurrentStep: function (stepIndex) {
            if (stepIndex >= controller.steps.length) {
                controller.phoneSelectedHandler(controller.selectedPhone);
                return;
            }

            controller.maxPhones = 4;
            controller.maxPhonesAdvanced = controller.isEmbedded ? 14 : 16;
            controller.criteria.showEditorsPickOnly = false;
            controller.phoneModal.find('#phone-include-dropdown').selectpicker('val', 'all');

            if (controller.currentStepIndex != null) { // If not initial load

                if (controller.hasValidationErrors && stepIndex > controller.currentStepIndex) {
                    return;
                }

                controller.saveToCriteria();
            }

            controller.currentStepIndex = stepIndex;
            controller.currentStep = controller.stepsConfig[controller.steps[stepIndex]];
            controller.phoneModal.find('[data-step]').hide();
            controller.phoneModal.find('[data-step-number]').text(stepIndex + 1);
            controller.phoneModal.find('[data-step-title]').text(controller.currentStep.title);

            if (controller.currentStep.name === 'filter-options' || controller.currentStep.name === 'filter-options-advanced' || controller.currentStep.name === 'phone-closest-matches') {
                controller.phoneModal.find('[data-continue-button]').addClass('invisible');
            } else {
                controller.phoneModal.find('[data-continue-button]').removeClass('invisible');
            }

            if (stepIndex === 0) {
                controller.phoneModal.find('[data-back-button]').hide();
            } else {
                controller.phoneModal.find('[data-back-button]').show();
            }

            controller.loadFromCriteria();

            controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"]').show();
        },

        // TODO: Try reducing cyclomatic complexity and enable the eslint rule
        // eslint-disable-next-line complexity
        loadFromCriteria: function () {
            if (controller.currentStep.name === 'phone-size') {
                controller.phoneModal.find('input[id^=phone-size-]').prop('checked', true);
                if (controller.criteria.phoneSizeSmall === false) {
                    controller.phoneModal.find('#phone-size-small').prop('checked', false);
                }
                if (controller.criteria.phoneSizeMedium === false) {
                    controller.phoneModal.find('#phone-size-medium').prop('checked', false);
                }
                if (controller.criteria.phoneSizeLarge === false) {
                    controller.phoneModal.find('#phone-size-large').prop('checked', false);
                }
                controller.phoneModal.find('input[id^=phone-size-]').off().change(function () {
                    controller.saveToCriteria();
                });
            } else if (controller.currentStep.name === 'payment-type') {
                controller.phoneModal.find('input[id^=price-type-]').prop('checked', true);
                if (controller.criteria.paymentTypeUpfront === false) {
                    controller.phoneModal.find('#price-type-upfront').prop('checked', false);
                }
                if (controller.criteria.paymentTypePlan === false) {
                    controller.phoneModal.find('#price-type-plan').prop('checked', false);
                }
                controller.phoneModal.find('input[id^=price-type-]').off().change(function () {
                    controller.saveToCriteria();
                });
            } else if (controller.currentStep.name === 'phone-cost') {
                controller.phoneModal.find('input[id^=phone-cost-]').prop('checked', true);
                if (controller.criteria.phoneCostCheap === false) {
                    controller.phoneModal.find('#phone-cost-cheap').prop('checked', false);
                }
                if (controller.criteria.phoneCostMidRange === false) {
                    controller.phoneModal.find('#phone-cost-midrange').prop('checked', false);
                }
                if (controller.criteria.phoneCostHighEnd === false) {
                    controller.phoneModal.find('#phone-cost-highend').prop('checked', false);
                }
                controller.phoneModal.find('input[id^=phone-cost-]').off().change(function () {
                    controller.saveToCriteria();
                });
            } else if (controller.currentStep.name === 'phone-brand') {

                controller.phoneModal.find('#select-all-brands').off().click(function () {
                    controller.phoneModal.find('[data-brand]').prop('checked', true);
                    controller.saveToCriteria();
                });

                controller.phoneModal.find('#unselect-all-brands').off().click(function () {
                    controller.phoneModal.find('[data-brand]').prop('checked', false);
                    controller.saveToCriteria();
                });

                controller.phoneModal.find('[data-brand]').prop('checked', false);
                controller.criteria.brands.filter(function (brand) {
                    controller.phoneModal.find('input[id="phone-brand-' + brand + '"]').prop('checked', true);
                });
                controller.phoneModal.find('input[id^=phone-brand-]').off().change(function () {
                    controller.saveToCriteria();
                });
            } else if (controller.currentStep.name === 'filter-options' || controller.currentStep.name === 'filter-options-advanced') {
                controller.showPhones();

                controller.phoneModal.find('[data-continue-button]').addClass('invisible');

                var popoverButton = controller.phoneModal.find('[data-phones-panel] [data-editors-pick-button]');
                popoverButton.popover();

                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').off().click(function () {
                    if (controller.currentStep.name === 'filter-options') {
                        controller.maxPhones += 4;
                    } else {
                        controller.maxPhonesAdvanced += controller.isEmbedded ? 14 : 16;
                    }
                    controller.showPhones(true);

                    var maxItems = controller.currentStep.name === 'filter-options' ? controller.maxPhones : controller.maxPhonesAdvanced;
                    if (controller.filteredPhones.length <= maxItems) {
                        controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').hide();
                    } else {
                        controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').show();
                    }
                });
                var maxItems = controller.currentStep.name === 'filter-options' ? controller.maxPhones : controller.maxPhonesAdvanced;
                if (controller.filteredPhones.length <= maxItems) {
                    controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').hide();
                } else {
                    controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').show();
                }

                var phoneSizes = [];
                if (controller.criteria.phoneSizeSmall === true) {
                    phoneSizes.push('small');
                }
                if (controller.criteria.phoneSizeMedium === true) {
                    phoneSizes.push('medium');
                }
                if (controller.criteria.phoneSizeLarge === true) {
                    phoneSizes.push('large');
                }
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-size-dropdown').selectpicker('val', phoneSizes);
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-size-dropdown').off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);

                if (controller.modalData.showMaxUpfront) {
                    var maxUpfrontDropdown =
                        controller.phoneModal.find('[data-step="' +
                            controller.currentStep.name +
                            '"] #phone-max-upfront-dropdown');
                    maxUpfrontDropdown.selectpicker('val', controller.criteria.maxUpfront);
                    maxUpfrontDropdown.off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);
                } else {
                    var paymentTypes = [];
                    if (controller.criteria.paymentTypeUpfront === true) {
                        paymentTypes.push('upfront');
                    }
                    if (controller.criteria.paymentTypePlan === true) {
                        paymentTypes.push('plan');
                    }
                    var paymentTypeDropdown =
                        controller.phoneModal.find('[data-step="' +
                            controller.currentStep.name +
                            '"] #phone-payment-type-dropdown');
                    paymentTypeDropdown.selectpicker('val', paymentTypes);
                    paymentTypeDropdown.off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);
                }

                var phonePrices = [];
                if (controller.criteria.phoneCostCheap === true) {
                    phonePrices.push('cheap');
                }
                if (controller.criteria.phoneCostMidRange === true) {
                    phonePrices.push('midrange');
                }
                if (controller.criteria.phoneCostHighEnd === true) {
                    phonePrices.push('highend');
                }
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-price-dropdown').selectpicker('val', phonePrices);
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-price-dropdown').off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);

                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-brand-dropdown').selectpicker('val', controller.criteria.brands);
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-brand-dropdown').off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);

                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-include-dropdown').off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);

                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #sort-dropdown').selectpicker('val', controller.criteria.sortBy);
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #sort-dropdown').off('change', controller.updatePhonesFilters).on('change', controller.updatePhonesFilters);
                
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [name="phone-search"]').val(controller.criteria.filter);
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [name="phone-search"]').off().keyup(function () {
                    controller.updatePhonesFilters(true);
                });

                controller.bindChooseButtons();
            }
            else if (controller.currentStep.name === 'phone-closest-matches') {
                var noteSpan = controller.phoneModal.find('[data-closest-match-note]');
                noteSpan.html(noteSpan.text().replace('{0}', '<strong>' + controller.selectedPhone.name + '</strong>'));

                var image = controller.phoneModal.find('[data-closest-match-image]');
                image.attr('alt', controller.selectedPhone.singleSupplierName);
                image.attr('src', controller.selectedPhone.singleSupplierImageUrl);

                controller.phoneModal.find('[data-yes-button]').off().click(function () {
                    controller.selectedPhone.similarPhones = true;
                    controller.selectedPhone.refurbishedPhones = 'Include';
                    controller.phoneModal.modal('hide');
                    controller.phoneSelectedHandler(controller.selectedPhone);
                });
                controller.phoneModal.find('[data-no-button]').off().click(function () {
                    controller.selectedPhone.similarPhones = false;
                    controller.selectedPhone.refurbishedPhones = 'Include';
                    controller.phoneModal.modal('hide');
                    controller.phoneSelectedHandler(controller.selectedPhone);
                });
            }
        },

        getPaymentTypes: function () {
            var types = [];
            if (controller.criteria.paymentTypeUpfront) {
                types.push('Outright');
            }
            if (controller.criteria.paymentTypePlan) {
                wo$.merge(types, controller.applicablePaymentTypes.filter(function (pt) {
                    return pt !== 'Outright';
                }));
            }
            return types;
        },

        bindChooseButtons: function () {
            controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [data-choose-button]').off().click(function () {
                var shortUrl = wo$(this).data('choose-button');
                controller.selectedPhone = controller.phones.filter(function (p) { return p.shortUrl === shortUrl; })[0];
                controller.selectedPhone.priceTypes = controller.getPaymentTypes();

                if (controller.selectedPhone.showSimilarPhonesStep === true) {
                    controller.setCurrentStep(controller.currentStepIndex + 1);
                } else {
                    controller.phoneModal.modal('hide');
                    controller.phoneSelectedHandler(controller.selectedPhone);
                }
            });

            controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [data-show-byo-button]').off();
            WhistleOut.MobilePhones.bindByoPopover(controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [data-show-byo-button]'),
                controller.modalData.byoPopoverContent,
                function (byoPhone) {
                    controller.selectedPhone = controller.phones.filter(function (p) { return p.shortUrl === null; })[0];
                    controller.selectedPhone.priceTypes = [];
                    controller.selectedPhone.byod = byoPhone;
                    controller.phoneModal.modal('hide');
                    controller.phoneSelectedHandler(controller.selectedPhone);
                },
                'right',
                controller.selectedByoPhoneModelId);
        },

        updatePhonesFilters: function (noAnimation) {
            if (noAnimation !== true) {
                noAnimation = false;
            }
            controller.maxPhones = 4;
            controller.maxPhonesAdvanced = controller.isEmbedded ? 14 : 16;
            controller.saveToCriteria();
            controller.showPhones(null, noAnimation);
            var maxItems = controller.currentStep.name === 'filter-options' ? controller.maxPhones : controller.maxPhonesAdvanced;
            if (controller.filteredPhones.length <= maxItems) {
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').hide();
            } else {
                controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #load-more-phones').show();
            }

        },

        showPhones: function (append, noAnimation) {
            var panel = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [data-phones-panel]');
            var startFrom = 0;

            if (append) {
                var currentlyShowing = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [data-phones-panel] [data-phone-short-url]').length;
                startFrom = currentlyShowing;
            } else {
                panel.html('');
            }

            var maxItems = controller.currentStep.name === 'filter-options' ? controller.maxPhones : controller.maxPhonesAdvanced;
            wo$.each(controller.filteredPhones, function (index, phone) {
                if (index >= maxItems)
                    return;

                if (index >= startFrom) {
                    controller.insertPhoneItem(phone, panel, noAnimation);
                }
            });

            var popoverButton = controller.phoneModal.find('[data-phones-panel] [data-editors-pick-button]');
            popoverButton.popover();

            controller.bindChooseButtons();
        },

        insertPhoneItem: function (phone, panel, noAnimation) {
            var phoneTemplateHtml = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] div#phone-item-template').html();
            if (phone.id === -1) {
                phoneTemplateHtml = phoneTemplateHtml.replace('{{button-data}}', 'data-show-byo-button');
            } else {
                phoneTemplateHtml = phoneTemplateHtml.replace('{{button-data}}', 'data-choose-button');
            }

            phoneTemplateHtml = phoneTemplateHtml.replace(/{{SHORT-URL}}/g, phone.shortUrl);
            phoneTemplateHtml = phoneTemplateHtml.replace(/data-image-url="{{IMAGE-URL}}"/g, 'src="' + phone.imageUrl.medium + '"');
            phoneTemplateHtml = phoneTemplateHtml.replace(/{{NAME}}/g, phone.name);
            if (phone.name && phone.name.length > 20) {
                phoneTemplateHtml = phoneTemplateHtml.replace(/{{CLASSNAME}}/g, ' font-2');
            }
            phoneTemplateHtml = phoneTemplateHtml.replace(/{{BRAND-NAME}}/g, phone.manufacturerName);
            phoneTemplateHtml = phoneTemplateHtml.replace(/{{EDITORS-PICK-STYLE}}/g, phone.isEditorsPick ? '' : 'display:none;');
            phoneTemplateHtml = phoneTemplateHtml.replace(/{{EDITORS-PICK-TEXT}}/g, phone.editorsPickText);
            if (phone.features && phone.features.length >= 4) {
                phoneTemplateHtml = phoneTemplateHtml.replace(/{{FEATURE-1}}/g, phone.features[0]);
                phoneTemplateHtml = phoneTemplateHtml.replace(/{{FEATURE-2}}/g, phone.features[1]);
                phoneTemplateHtml = phoneTemplateHtml.replace(/{{FEATURE-3}}/g, phone.features[2]);
                phoneTemplateHtml = phoneTemplateHtml.replace(/{{FEATURE-4}}/g, phone.features[3]);
            }

            var availableFrom = phone.supplierCount === 1 ? controller.modalData.carrier1 : controller.modalData.carrierX.replace('{0}', phone.supplierCount);
            phoneTemplateHtml = phoneTemplateHtml.replace(/{{AVAILABLE-FROM}}/g, availableFrom);

            var li = wo$(phoneTemplateHtml);
            if (phone.is5GCapable) {
                li.find('[data-has-5g]').show();
            } else {
                li.find('[data-has-5g]').hide();
            }

            if (noAnimation) {
                panel.append('\n\t');
                li.appendTo(panel).show();
            } else {
                panel.append('\n\t');
                li.appendTo(panel).fadeIn('medium');
            }
        },

        saveToCriteria: function () {
            if (controller.currentStep.name === 'phone-size') {
                controller.criteria.phoneSizeSmall = controller.phoneModal.find('#phone-size-small').prop('checked');
                controller.criteria.phoneSizeMedium = controller.phoneModal.find('#phone-size-medium').prop('checked');
                controller.criteria.phoneSizeLarge = controller.phoneModal.find('#phone-size-large').prop('checked');
            } else if (controller.currentStep.name === 'payment-type') {
                controller.criteria.paymentTypeUpfront = controller.phoneModal.find('#price-type-upfront').prop('checked');
                controller.criteria.paymentTypePlan = controller.phoneModal.find('#price-type-plan').prop('checked');
            } else if (controller.currentStep.name === 'phone-cost') {
                controller.criteria.phoneCostCheap = controller.phoneModal.find('#phone-cost-cheap').prop('checked');
                controller.criteria.phoneCostMidRange = controller.phoneModal.find('#phone-cost-midrange').prop('checked');
                controller.criteria.phoneCostHighEnd = controller.phoneModal.find('#phone-cost-highend').prop('checked');
            } else if (controller.currentStep.name === 'phone-brand') {
                controller.criteria.brands = [];
                controller.phoneModal.find('input[id^=phone-brand-]:checked').each(function () {
                    controller.criteria.brands.push(wo$(this).attr('data-brand'));
                });
            } else if (controller.currentStep.name === 'filter-options' || controller.currentStep.name === 'filter-options-advanced') {
                controller.criteria.phoneSizeSmall = false;
                controller.criteria.phoneSizeMedium = false;
                controller.criteria.phoneSizeLarge = false;
                var sizes = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-size-dropdown').selectpicker('val');
                if (sizes && sizes.length > 0) {
                    wo$.each(sizes, function (index, size) {
                        if (size === 'small') {
                            controller.criteria.phoneSizeSmall = true;
                        } else if (size === 'medium') {
                            controller.criteria.phoneSizeMedium = true;
                        }
                        else if (size === 'large') {
                            controller.criteria.phoneSizeLarge = true;
                        }
                    });
                }

                if (controller.modalData.showMaxUpfront) {
                    controller.criteria.maxUpfront = parseInt(controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-max-upfront-dropdown').selectpicker('val'));
                } else {
                    controller.criteria.paymentTypeUpfront = false;
                    controller.criteria.paymentTypePlan = false;
                    var types = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-payment-type-dropdown').selectpicker('val');
                    if (types && types.length > 0) {
                        wo$.each(types, function (index, type) {
                            if (type === 'upfront') {
                                controller.criteria.paymentTypeUpfront = true;
                            } else if (type === 'plan') {
                                controller.criteria.paymentTypePlan = true;
                            }
                        });
                    }
                }

                controller.criteria.phoneCostCheap = false;
                controller.criteria.phoneCostMidRange = false;
                controller.criteria.phoneCostHighEnd = false;
                var prices = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-price-dropdown').selectpicker('val');
                if (prices && prices.length > 0) {
                    wo$.each(prices, function (index, price) {
                        if (price === 'cheap') {
                            controller.criteria.phoneCostCheap = true;
                        } else if (price === 'midrange') {
                            controller.criteria.phoneCostMidRange = true;
                        }
                        else if (price === 'highend') {
                            controller.criteria.phoneCostHighEnd = true;
                        }
                    });
                }

                controller.criteria.brands = [];
                var brands = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-brand-dropdown').selectpicker('val');
                if (brands && brands.length > 0) {
                    controller.criteria.brands = brands;
                }

                var includeValue = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #phone-include-dropdown').selectpicker('val');
                if (includeValue === 'editorspick') {
                    controller.criteria.showEditorsPickOnly = true;
                } else {
                    controller.criteria.showEditorsPickOnly = false;
                }

                controller.criteria.sortBy = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] #sort-dropdown').selectpicker('val');

                controller.criteria.filter = controller.phoneModal.find('[data-step="' + controller.currentStep.name + '"] [name="phone-search"]').val();
            }

            controller.filterPhones();
        },

        filterSize: function (p) {
            if (p.id === -1)
                return true;
            if (controller.criteria.phoneSizeSmall === false && p.size === 'small')
                return false;
            if (controller.criteria.phoneSizeMedium === false && p.size === 'medium')
                return false;
            if (controller.criteria.phoneSizeLarge === false && p.size === 'large')
                return false;
            return true;
        },

        filter5G: function (p) {
            if (p.id === -1)
                return true;
            if (controller.criteria.sortBy === '5G' && p.is5GCapable === false)
                return false;
            return true;
        },

        filterPaymentTypes: function (p) {
            if (controller.modalData.showMaxUpfront)
                return true;
            if (p.id === -1)
                return true;
            if (!controller.criteria.paymentTypeUpfront || !controller.criteria.paymentTypePlan) {
                var types = controller.getPaymentTypes();
                if (wo$(types).filter(p.paymentTypes).length === 0)
                    return false;
            }
            return true;
        },

        filterMaxUpfront: function (p) {
            if (!controller.modalData.showMaxUpfront)
                return true;
            if (p.id === -1 || controller.criteria.maxUpfront === -1)
                return true;
            if (p.minimumUpfront == null || p.minimumUpfront > controller.criteria.maxUpfront) {
                return false;
            }
            return true;
        },

        filterCost: function (p) {
            if (p.id === -1)
                return true;
            if (controller.criteria.phoneCostCheap === false && p.priceRange === 'cheap')
                return false;
            if (controller.criteria.phoneCostMidRange === false && p.priceRange === 'midrange')
                return false;
            if (controller.criteria.phoneCostHighEnd === false && p.priceRange === 'highend')
                return false;
            return true;
        },

        filterBrand: function (p) {
            if (p.id === -1)
                return true;
            if (controller.criteria.brands.filter(function (brand) { return p.manufacturerShortUrl === brand; }).length === 0) {
                return false;
            }
            return true;
        },

        filterEditorsPick: function (p) {
            if (p.id === -1)
                return true;
            if (controller.criteria.showEditorsPickOnly === true && !p.isEditorsPick) {
                return false;
            }
            return true;
        },

        filterSearch: function (p) {
            if (p.id === -1)
                return true;
            if (controller.criteria.filter.length && p.name.toLowerCase().indexOf(controller.criteria.filter.toLowerCase()) < 0 && p.manufacturerName.toLowerCase().indexOf(controller.criteria.filter.toLowerCase()) < 0) {
                return false;
            }
            return true;
        },

        sortPhones: function (a, b) {
            if (a.id === -1) return -1;

            if (controller.criteria.sortBy === 'popular') {
                if (a.rank < b.rank) return -1;
                if (a.rank > b.rank) return 1;
                return 0;
            } else if (controller.criteria.sortBy === 'model') {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            }

            if (a.rank < b.rank) return -1;
            if (a.rank > b.rank) return 1;
            return 0;
        },

        filterPhones: function () {
            controller.filteredPhonesAllBrands = controller.phones.slice().filter(function(p) {
                return (controller.currentStep.name === 'filter-options-advanced' || p.shortUrl) &&
                    controller.filterSize(p) &&
                    controller.filterPaymentTypes(p) &&
                    controller.filterMaxUpfront(p) &&
                    controller.filterCost(p) &&
                    controller.filterEditorsPick(p) &&
                    controller.filterSearch(p);
            });

            controller.filteredPhones = controller.filteredPhonesAllBrands.slice().filter(function (p) {
                return controller.filterBrand(p)
                        && controller.filter5G(p);
            }).sort(controller.sortPhones);

            var oldCount = parseInt(controller.phoneModal.find('[data-phones-count]').text());
            if (!oldCount || oldCount === 'NaN' || oldCount < 0) {
                oldCount = 0;
            }

            var newCount = controller.filteredPhones.length;
            if (controller.modalData.showSimOnlyFilter) {
                newCount -= 1;
            }
            if (newCount < 0) {
                newCount = 0;
            }

            if (oldCount !== newCount) {
                var countSubheader = controller.phoneModal.find('[data-count-subheader]');
                var countSpan = controller.phoneModal.find('[data-phones-count]');
                countSubheader.addClass('highlight-fade');
                setTimeout(function () {
                    countSubheader.removeClass('highlight-fade');
                }, 1300);
                countSpan.countTo({ from: oldCount, to: newCount, speed: 500 });
            }

            controller.phoneModal.find('[data-brand-phones-count]').each(function () {
                var span = wo$(this);
                var brandShortUrl = span.data('brand-phones-count');
                var brandPhoneCount = controller.filteredPhonesAllBrands.filter(function (p) {
                    return p.manufacturerShortUrl === brandShortUrl;
                }).length;
                span.text(brandPhoneCount);
            });

            controller.hasValidationErrors = false;
            controller.phoneModal.find('[data-validation-message]').hide();
            controller.phoneModal.find('[data-count-subheader]').show();

            if (controller.currentStep.name === 'filter-options' || controller.currentStep.name === 'filter-options-advanced' || controller.currentStep.name === 'phone-closest-matches') {
                controller.phoneModal.find('[data-continue-button]').addClass('invisible');
            } else {
                controller.phoneModal.find('[data-continue-button]').removeClass('invisible');
            }

            controller.phoneModal.find('[data-continue-button]').prop('disabled', false);
            controller.phoneModal.find('[data-back-button]').prop('disabled', false);

            if (!controller.criteria.phoneSizeSmall && !controller.criteria.phoneSizeMedium && !controller.criteria.phoneSizeLarge) {
                controller.hasValidationErrors = true;
                controller.phoneModal.find('[data-count-subheader]').hide();
                controller.phoneModal.find('[data-no-options-validation]').show();
                controller.phoneModal.find('[data-continue-button]').prop('disabled', true);
                controller.phoneModal.find('[data-back-button]').prop('disabled', true);
                return;
            }
            if (!controller.criteria.paymentTypeUpfront && !controller.criteria.paymentTypePlan) {
                controller.hasValidationErrors = true;
                controller.phoneModal.find('[data-count-subheader]').hide();
                controller.phoneModal.find('[data-no-options-validation]').show();
                controller.phoneModal.find('[data-continue-button]').prop('disabled', true);
                controller.phoneModal.find('[data-back-button]').prop('disabled', true);
                return;
            }
            if (!controller.criteria.phoneCostCheap && !controller.criteria.phoneCostMidRange && !controller.criteria.phoneCostHighEnd) {
                controller.hasValidationErrors = true;
                controller.phoneModal.find('[data-count-subheader]').hide();
                controller.phoneModal.find('[data-no-options-validation]').show();
                controller.phoneModal.find('[data-continue-button]').prop('disabled', true);
                controller.phoneModal.find('[data-back-button]').prop('disabled', true);
                return;
            }
            if (controller.criteria.brands.length <= 0) {
                controller.hasValidationErrors = true;
                controller.phoneModal.find('[data-count-subheader]').hide();
                controller.phoneModal.find('[data-no-options-validation]').show();
                controller.phoneModal.find('[data-continue-button]').prop('disabled', true);
                controller.phoneModal.find('[data-back-button]').prop('disabled', true);
                return;
            }
            if (controller.filteredPhones.length <= 0) {
                controller.hasValidationErrors = true;
                controller.phoneModal.find('[data-count-subheader]').hide();
                controller.phoneModal.find('[data-no-results-validation]').show();
                controller.phoneModal.find('[data-continue-button]').prop('disabled', true);
                controller.phoneModal.find('[data-back-button]').prop('disabled', true);
            }
        }
    };

    WhistleOut.phonePickerController = controller;

});
